
@font-face {
  font-family: 'Anurati-regular';
  src:url('../fonts/anurati_regular.otf?-kwf309');
  src:url('../fonts/anurati_regular.otf?#iefix-kwf309') format('embedded-opentype'),
    url('../fonts/anurati_regular.otf?-kwf309') format('woff'),
    url('../fonts/anurati_regular.otf?-kwf309') format('truetype'),
    url('../fonts/anurati_regular.otf?-kwf309#AA-Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}  

@font-face {
    font-family: 'Montserrat-Regular';
    src:url('../fonts/Montserrat-Regular.ttf?-kwf309');
    src:url('../fonts/Montserrat-Regular.ttf?#iefix-kwf309') format('embedded-opentype'),
      url('../fonts/Montserrat-Regular.ttf?-kwf309') format('woff'),
      url('../fonts/Montserrat-Regular.ttf?-kwf309') format('truetype'),
      url('../fonts/Montserrat-Regular.ttf?-kwf309#AA-Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }  
  
  @font-face {
    font-family: 'Montserrat-Bold';
    src:url('../fonts/Montserrat-Bold.ttf?-kwf309');
    src:url('../fonts/Montserrat-Bold.ttf?#iefix-kwf309') format('embedded-opentype'),
      url('../fonts/Montserrat-Bold.ttf?-kwf309') format('woff'),
      url('../fonts/Montserrat-Bold.ttf?-kwf309') format('truetype'),
      url('../fonts/Montserrat-Bold.ttf?-kwf309#AA-Montserrat-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat-SemiBold';
    src:url('../fonts/Montserrat-SemiBold.ttf?-kwf309');
    src:url('../fonts/Montserrat-SemiBold.ttf?#iefix-kwf309') format('embedded-opentype'),
      url('../fonts/Montserrat-SemiBold.ttf?-kwf309') format('woff'),
      url('../fonts/Montserrat-SemiBold.ttf?-kwf309') format('truetype'),
      url('../fonts/Montserrat-SemiBold.ttf?-kwf309#AA-Montserrat-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat-Medium';
    src:url('../fonts/Montserrat-Medium.ttf?-kwf309');
    src:url('../fonts/Montserrat-Medium.ttf?#iefix-kwf309') format('embedded-opentype'),
      url('../fonts/Montserrat-Medium.ttf?-kwf309') format('woff'),
      url('../fonts/Montserrat-Medium.ttf?-kwf309') format('truetype'),
      url('../fonts/Montserrat-Medium.ttf?-kwf309#AA-Montserrat-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  